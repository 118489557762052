import React, { useRef, useContext } from 'react'
import { string, shape, arrayOf, number } from 'prop-types'
import classNames from 'classnames'
import Link from '@/utils/Link'
import MegaMenu from './MegaMenu'
import useHeadroom from '@/hooks/useHeadroom'
import { stripSlashes } from '@/utils'
import IconWrapper from '@/utils/IconWrapper'
import Logo from '@/icons/logo.svg'
import { StickyNavContext } from '@/container/StickyNavContext'

import styles from './Header.module.css'

function Header({ pathname, menu, isLandingPage, cta }) {
	const $node = useRef()
	const { innerStyle, wrapper, state } = useHeadroom($node)
	const { isStickyNav } = useContext(StickyNavContext)
	const current = stripSlashes(pathname)
	const segments = current.split('/')
	const [segmentOne] = segments
	const productPage = segmentOne === 'product'

	const isPinned = state === 'pinned'

	return (
		<header
			ref={ $node }
			className={ classNames(styles.header, 'w-full flex') }
			style={ {
				...wrapper,
				position: 'relative',
				zIndex: '90'
			} }
		>
			<div
				className={ classNames('w-full md:text-black flex', styles.inner, {
					'bg-white': isPinned,
					'md:text-darkgrey': isPinned
				}) }
				style={ isStickyNav && !productPage ? innerStyle : {} }
			>
				<div
					className={ classNames(
						'wrapper flex items-center justify-between py-5 md:py-0 lg:px-8 transition-bg-color transition-color'
					) }
				>
					<IconWrapper
						icon={ Logo }
						as={ Link }
						to="/"
						label="Ultraleap"
						wrapperClassName={ classNames('block w-full max-w-logo-fluid z-50', {
							'text-darkgrey': isPinned
						}) }
					/>

					{/* {
						isLandingPage ? null :
							<Nav
								wordpress_id={ wordpress_id }
								segmentOne={ segmentOne }
								menu={ menu }
								segments={ segments.length }
								pageUrl={ current }
							/>
					} */}

					{ isLandingPage ? null :
						<MegaMenu
							menu={ menu }
							cta={ menu.cta }
						/>
					}

				</div>
			</div>
		</header>
	)
}

Header.propTypes = {
	pathname: string.isRequired,
	wordpress_id: number,
	menu: shape({
		items: arrayOf(
			shape({
				title: string.isRequired,
				db_id: number.isRequired,
				url: string.isRequired,
				child_items: arrayOf(
					shape({
						title: string.isRequired.isRequired,
						db_id: number.isRequired.isRequired,
						url: string.isRequired.isRequired,
						child_items: arrayOf(
							shape({
								title: string.isRequired.isRequired,
								db_id: number.isRequired.isRequired,
								url: string.isRequired.isRequired
							})
						)
					})
				)
			})
		).isRequired,
		cta: shape({
			header_cta: shape({
				url: string,
				title: string,
				target: string,
			}),
			header_cta_colour: string,
			header_cta_style: string,
		})
	}).isRequired,
}

export default Header

/*
 */
