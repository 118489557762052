/* eslint-disable react/no-danger, react/prop-types */
import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { shape, string } from 'prop-types'
import CookieConsent from 'react-cookie-consent'
import { Helmet } from 'react-helmet-async'
import { AnimatePresence, motion } from 'framer-motion'
import Header from '@/components/global/Header'
import Footer from '@/components/global/Footer'
import Link from '@/utils/Link'
import '@/style/styles.css'

const transition = { duration: 0.5, ease: [0.43, 0.13, 0.23, 0.96] }

export const variants = {
	initial: { opacity: 0 },
	enter: { opacity: 1, transition },
	exit: {
		opacity: 0,
		transition: { duration: 1.5, ...transition }
	}
}

function Layout({
	children,
	location: { pathname },
	pageContext: { media, wordpress_id, template, categories }
}) {
	// the media JSON is not available on pages created by gatsby
	// only the 404 page is generated by gatsby
	// the images are only used on haptic/tracking child pages
	// so this shouldn't be an issue... if it is... kill me
	const images = media ? JSON.parse(media) : {}
	const {
		mainMenu,
		footerMenu,
		legalMenu,
		headerCta: {
			options: headerCta
		}
	} = useStaticQuery(graphql`
		{
			mainMenu: allWordpressMenusMenusItems(
				filter: { name: { eq: "Mega Menu" } }
			) {
				nodes {
					items {
						title
						db_id
						url

						child_items {
							title
							db_id
							url
							thumbnail_id
							menu_item_parent
							wordpress_id

							child_items {
								title
								db_id
								url
								thumbnail_id
								menu_item_parent
								wordpress_id
							}
						}
					}
				}
			}
			headerCta: wordpressAcfOptions {
				options {
					header_cta {
						url
						title
						target
					}
					header_cta_colour
					header_cta_style
				}
			}
			footerMenu: allWordpressMenusMenusItems(
				filter: { name: { eq: "Footer Menu" } }
			) {
				nodes {
					items {
						title
						db_id
						url
						child_items {
							title
							db_id
							url
						}
					}
				}
			}
			legalMenu: allWordpressMenusMenusItems(
				filter: { name: { eq: "Footer Legal" } }
			) {
				nodes {
					items {
						title
						db_id
						url
					}
				}
			}
		}
	`)

	const topNav = mainMenu.nodes[0].items.map(item => {
		if (item.child_items) {
			return {
				...item,
				child_items: item.child_items.map(child => {
					// media won't be available on the 404 page
					// shouldn't be an issue
					if (child.thumbnail_id && media) {
						return {
							...child,
							image: images[child.thumbnail_id],
							url:
								child.title.toLowerCase() === 'how it works'
									? `${ child.url }#how-it-works`
									: child.url
						}
					}
					return child
				})
			}
		}

		return item
	})

	const isResource = categories ? categories.map(cat => cat.name).includes('Resources') : false
	const isLandingPage = template === 'template-landing-page.php' || isResource

	return (
		<>
			<Helmet>
				<title>Ultraleap</title>
				<style>
					{
						`.has-text-align-center {
							text-align: center;
						}`
					}
				</style>
			</Helmet>

			<Header
				wordpress_id={ wordpress_id }
				menu={ {
					items: topNav,
					cta: headerCta,
				} }
				pathname={ pathname }
				isLandingPage={ isLandingPage }
			/>
			<AnimatePresence exitBeforeEnter initial={ false }>
				<motion.main
					variants={ variants }
					initial="initial"
					animate="enter"
					exit="exit"
					key={ pathname }
				>
					{ children }
				</motion.main>
			</AnimatePresence>
			<Footer
				legal={ legalMenu.nodes[0] }
				menu={ footerMenu.nodes[0] }
				isLandingPage={ isLandingPage }
			/>
			<CookieConsent
				location="bottom"
				buttonText="Accept"
				containerClasses="p-3 items-start"
				buttonClasses="mt-3 lg:mt-0 font-bold bg-primary text-white text-white transition-all inline-flex items-center justify-center text-center text-sm text-left font-museoSans py-2 px-4 hover:px-8 relative rounded-full"
				cookieName="ultraleapCookie"
				buttonStyle={ {
					color: '',
					fontSize: '',
					background: '',
					borderRadius: '',
					padding: '',
					margin: ''
				} }
				contentStyle={ { flex: '', padding: '', margin: '' } }
				style={ { background: '#2B373B', alignItems: 'flex-start' } }
				expires={ 150 }
				onAccept={() => {
					document.body.classList.add('cookie-consent-accepted');
				}}
			>
				<div className="max-w-4xl text-sm">
					We use cookies to help enhance your browsing experience. By clicking
					“Accept,” you agree to the use of third-party cookies and other
					similar technologies. Please review our{ ' ' }
					<Link
						className="underline pardotTrackClick text-primary"
						style={ { textDecoration: 'underline' } }
						to="/privacy-policy/"
					>
						privacy policy
					</Link>{ ' ' }
					for more information.
				</div>
			</CookieConsent>
		</>
	)
}

Layout.propTypes = {
	location: shape({
		pathname: string
	})
}

export default Layout
