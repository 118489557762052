import React, { useState, useContext } from 'react'
import { string, shape, arrayOf, number } from 'prop-types'
import classNames from 'classnames'
import useTransformMenuProps from '@/hooks/useTransformMenuProps'
import { NavContext } from '@/container/NavContext'
import useMediaQuery from '@/hooks/useMediaQuery'

import Link from '@/utils/Link'
// import Button from '../Button';
import MenuButton from '../MenuButton'
import MenuItem from './MenuItem';
import styles from './MegaMenu.module.css';

const MegaMenu = ({ menu, cta }) => {
    const menuItems = useTransformMenuProps(process.env.SOURCE, menu)

	const [toggled, setToggled] = useState(-1);
	const handleToggle = (index) => {
		if (toggled === index) {
			setToggled(-1);
		} else {
			setToggled(index);
		}
	};

    const isMobile = useMediaQuery('(max-width: 768)')

	const { isOpen } = useContext(NavContext)


	const ctaStyle = {
		backgroundColor: 'transparent',
		borderColor: 'transparent',
		color: cta.header_cta_colour && cta.header_cta_style === 'solid' ? 'white' : cta.header_cta_colour ? cta.header_cta_colour : 'black'
	}

	if (cta.header_cta_colour && cta.header_cta_style === 'solid') {
		ctaStyle.backgroundColor = cta.header_cta_colour;
	}

	if (cta.header_cta_colour && cta.header_cta_style === 'transparent') {
		ctaStyle.borderColor = cta.header_cta_colour;
	}

	if (cta.header_cta_colour && cta.header_cta_style === 'solid') {
		ctaStyle.color = 'white';
	} else if (cta.header_cta_colour && cta.header_cta_style === 'transparent') {
		ctaStyle.color = cta.header_cta_colour;
	} else {
		ctaStyle.color = 'black';
	}

	return (
		<>
			<nav
				aria-hidden={ isMobile && !isOpen }
				className={ styles.nav }
			>
				<div
					className={ classNames(`absolute top-0 right-0 w-full opacity-100 pointer-events-none bg-white md:hidden ${ styles.fullHeight }`, { 'hidden': !isOpen }) }
				/>
				<div
					className={ classNames(
						`absolute inset-0 flex flex-col items-center ${ styles.fullHeight }`,
						'md:h-auto md:static md:flex-row md:justify-end md:pointer-events-auto',
						{ 'pointer-events-none ': !isOpen }
					) }
				>
					<ul
						className={
							classNames(
								"md:flex md:opacity-100 md:visible",
								{ 'invisible': !isOpen, 'opacity-0': !isOpen },
								styles.topUl
							)
						}
					>
						{ menuItems.map((item, i) => (
							<MenuItem
								toggled={ toggled === i }
								handleToggle={ () => handleToggle(i) }
								key={ item.db_id }
								url={ item.url }
								title={ item.title }
								child_items={ item.child_items }
							/>
						)) }

						{ cta && cta.header_cta && cta.header_cta.title && cta.header_cta.url &&
							<li className={ styles.ctaWrapper }>
								<Link
									to={ cta.header_cta.url }
									target={ cta.header_cta.target }
									className={ styles.cta }
									style={ ctaStyle }
								>
									{ cta.header_cta.title }
								</Link>
							</li>
						}
					</ul>

				</div>
			</nav>

			<MenuButton className="absolute top-0 bottom-0 right-4 z-50 my-auto md:hidden" />
		</>
	);
};

MegaMenu.propTypes = {
	menu: shape({
		items: arrayOf(
			shape({
				title: string.isRequired,
				db_id: number.isRequired,
				url: string.isRequired,
				child_items: arrayOf(
					shape({
						title: string.isRequired.isRequired,
						db_id: number.isRequired.isRequired,
						url: string.isRequired.isRequired,
						image: shape({
							url: string,
							alt: string,
						}),
						child_items: arrayOf(
							shape({
								title: string.isRequired.isRequired,
								db_id: number.isRequired.isRequired,
								url: string.isRequired.isRequired
							})
						)
					})
				)
			})
		).isRequired
	}).isRequired,

	cta: shape({
		header_cta: shape({
			url: string,
			title: string,
			target: string,
		}),
		header_cta_colour: string,
		header_cta_style: string,
	})
}

export default MegaMenu;